<template>
  <div class="login-wrap">
    <div class="content">
      <div class="bg">
        <img src="../assets/images/login-title.png">
      </div>
      <div class="name">配件销售系统</div>
      <div class="box">
        <ul>
          <li>
            <div class="ico">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#iconwodedangxuan"></use>
              </svg>
            </div>
            <div class="inp">
              <input type="tel" placeholder="请输入手机号" maxlength="11" autocomplete="off" v-model="loginForm.phone" @blur="inputBlur" @focusout="inputBlur">
            </div>
          </li>
          <li v-if="loginForm.loginType === 1">
            <div class="ico">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#iconmima"></use>
              </svg>
            </div>
            <div class="inp">
              <input type="password" placeholder="请输入登录密码" v-model="loginForm.password" @blur="inputBlur" @focusout="inputBlur">
            </div>
          </li>
          <li v-if="loginForm.loginType === 2">
            <div class="ico">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#iconyanzhengma"></use>
              </svg>
            </div>
            <div class="inp">
              <input type="text" placeholder="请输入验证码" v-model="loginForm.code" @blur="inputBlur" @focusout="inputBlur">
            </div>
            <van-button type="primary" class="getCode" :disabled="getCodeNoTap" :class="{disabled:getCodeNoTap}" @click="!getCodeNoTap?getCode():''">{{CodeText}}</van-button>
          </li>
        </ul>
        <div class="change-mode">
          <div class="short-letter" @click="changeLoginType">{{loginForm.loginTypeTxt}}</div>
          <div class="remember-pwd" v-if="loginForm.loginType === 1">
            <van-checkbox v-model="loginForm.rememberPwd" shape="square" checked-color="rgba(105, 129, 251, 1)">记住密码</van-checkbox>
          </div>
        </div>
        <div class="submit">
          <van-button type="primary" :disabled="!subActive" @click="subActive?login():''">登录</van-button>
        </div>
        <div class="forget">
          <span @click="$router.push('/forgotPassword')">忘记密码？</span>
          <span @click="$router.push({path: '/register', query: {invitationCode: $route.query.invitationCode}})">没有账号，去注册</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Checkbox, Button, Toast } from 'vant'
export default {
  components: {
    [Checkbox.name]: Checkbox,
    [Button.name]: Button,
    [Toast.name]: Toast
  },
  data () {
    return {
      loginForm: {
        loginType: 1,                                          // 登录类型1：账号密码 2：手机验证码
        loginTypeTxt: '用短信验证码登录',
        rememberPwd: false,                                    // 是否记住密码 默认否
        phoe: '',                                              // 手机号
        password: '',                                          // 密码
        code: '',                                              // 验证码
        codeVal: ''                                            // 获取的验证码值
      },
      Time: 60,                                                // 倒计时时间
      getCodeNoTap: false,                                     // 获取验证码按钮是否允许点击
      CodeText: '发送验证码',                                   // 验证码文字
      timer: null,                                             // 定时器
      subActive: false                                         // 提交按钮选中效果
    }
  },
  watch: {
    'loginForm': {
      handler () {
        this.subActive = (this.loginForm.loginType === 1 && this.loginForm.phone && this.loginForm.password) || (this.loginForm.loginType === 2 && this.loginForm.phone && this.loginForm.code) ? !!true : !!false
      },
      deep: true
    }
  },
  mounted () {
    // 记住密码
    var accountMes = JSON.parse(localStorage.getItem('ACCOUNTMES'))
    if (accountMes && JSON.stringify(accountMes) !== '{}') {
      this.loginForm.phone = accountMes.userName
      this.loginForm.password = accountMes.password
      this.loginForm.rememberPwd = accountMes.rememberPwd
    }
  },
  beforeDestroy () {
    // Toast.clear()               // 清除loading状态
  },
  beforeRouteEnter (to, from, next) {
    next()
  },
  methods: {
    /**
     * input 失去焦点
    */
    inputBlur () {
      var u = navigator.userAgent
      var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
      if (isIOS) {
        document.body.scrollTop = document.documentElement.scrollTop = 0
        // this.$utils.blurAdjust()
      }
    },
    /**
     * 切换登录方式
    */
    changeLoginType () {
      this.loginForm.loginType = this.loginForm.loginType === 1 ? 2 : 1
      this.loginForm.loginTypeTxt = this.loginForm.loginType === 1 ? '用短信验证码登录' : '用密码登录'
    },
    /**
     * 倒计时
     */
    countDown () {
      if (this.Time > 0) {
        this.getCodeNoTap = true
        this.CodeText = this.Time + 's'
        --this.Time
      } else {
        clearInterval(this.timer)
        this.Time = 60
        this.getCodeNoTap = false
        this.CodeText = '重新获取'
      }
    },
    /**
     * 获取验证码
     */
    getCode () {
      if (!this.loginForm.phone) {
        Toast('请输入手机号')
        return false
      } if (!this.loginForm.phone.match(/^1\d{10}$/g)) {
        Toast('请输入正确的手机号')
        return false
      }
      // 倒计时
      this.sendCode()
      this.countDown()
      this.timer = setInterval(() => {
        this.countDown()
      }, 1000)
    },
    /**
     * 检测验证码是否一致
     */
    checkCode (fun) {
      return new Promise((resolve, reject) => {
        this.$http.post(`${this.api.checkVerifyCode}`, {
          phone: this.loginForm.phone,
          verifyCode: this.loginForm.code
        }).then((res) => {
          if (parseInt(res.data.status) !== parseInt(this.api.ERR_OK)) {
            Toast(res.data.msg)
            return false
          }
          resolve()
        })
      })
    },
    /**
     * 发送验证码
     */
    sendCode () {
      this.$http.post(`${this.api.getValidWord}`, {
        phone: this.loginForm.phone
      }).then((res) => {
        Toast(res.data.msg)
        if (res.data.data) {
          this.loginForm.codeVal = res.data.data
        }
      })
    },
    /**
     * 登录校验
    */
    loginCheck () {
      return new Promise((resolve, reject) => {
        if (!this.loginForm.phone) {
          Toast('请输入手机号')
          return false
        } else if (!this.loginForm.phone.match(/^1\d{10}$/g)) {
          Toast('请输入正确的手机号')
          return false
        } else if (this.loginForm.loginType === 1) {
          if (!this.loginForm.password) {
            Toast('请输入密码')
            return false
          } else if (this.loginForm.password.length < 6 || this.loginForm.password.length > 20) {
            Toast('密码限6-20个字符')
            return false
          }
        } else if (this.loginForm.loginType === 2) {
          if (!this.loginForm.code) {
            Toast('请输入验证码')
            return false
          } else if (this.loginForm.code.length !== 4) {
            Toast('验证码限4个字符')
            return false
          }
        }
        resolve()
      })
    },
    /**
     * 登录
    */
    async login () {
      await this.loginCheck()
      var accountMes = {}    // 要存储的账户信息
      if (this.loginForm.loginType === 2) {
        await this.checkCode()
      }
      Toast.loading({
        message: '登录中...',
        forbidClick: true,
        duration: 0
      })
      this.$http.post(`${this.api.login}`, {
        phone: this.loginForm.phone,
        password: this.loginForm.loginType === 1 ? this.loginForm.password : this.loginForm.code
      }).then((res) => {
        localStorage.setItem('accountMes', JSON.stringify(accountMes))
        if (res.data.status !== this.api.ERR_OK || !res) {
          Toast(res.data.msg)
          return false
        } else {
          Toast.clear()
          Toast('登录成功')
          // 是否记住密码
          accountMes.userName = this.loginForm.rememberPwd ? this.loginForm.phone : ''
          accountMes.password = this.loginForm.rememberPwd ? this.loginForm.password : ''
          accountMes.rememberPwd = this.loginForm.rememberPwd
          localStorage.setItem('ACCOUNTMES', JSON.stringify(accountMes))
          localStorage.setItem('LZWLUSERINFO', JSON.stringify(res.data.data))
          if (localStorage.getItem('REDIRECT_URL') && localStorage.getItem('REDIRECT_URL') !== '/bootPage') {
            this.$router.replace({
              path: localStorage.getItem('REDIRECT_URL')
            })
            localStorage.removeItem('REDIRECT_URL')
          } if (localStorage.getItem('REDIRECT_URL') === '/') {
            this.$router.replace('/index')
          } else {
            this.$router.replace('/index')
          }
        }
      })
    }
  }
}
</script>
<style lang="less">
.login-wrap{
  height: calc(100vh);
  overflow: auto;
  background: url('../assets/images/login-bg.png') no-repeat;
  background-size: cover;
  .content{
    width: 100%;
    height: 100%;
    overflow: auto;
    background:rgba(28,30,58,0.8);
  }
  .bg{
    padding-top: 175px;
    img{
      width: 551px;
      height: 164px;
    }
  }
  .name{
    color: #fff;
    font-size: 30px;
    text-align: right;
    padding-right: 109px;
    margin-top: 21px;
  }
  .box{
    padding: 0 60px;
  }
  // form
  ul{
    li{
      display: flex;
      height: 127px;
      align-items: center;
      border-bottom: 1px solid rgba(105, 106, 134, 1);
      .ico{
        margin-right: 30px;
        svg{
          width: 35px;
          height: 34px;
          fill: rgba(131, 132, 160, 1);
        }
      }
      .inp{
        flex: 1;
        input{
          width: 100%;
          height: 127px;
          background: none;
          outline: none;
          font-size: 28px;
          color: #fff;
        }
      }
      .getCode{
        width:203px;
        height:65px;
        font-size: 26px;
        padding: 0;
        line-height: 65px;
        border: none;
        background:rgba(105,129,251,1);
        border-radius:10px;
        cursor: pointer;
        &.disabled{
          background: #ccc;
        }
      }
    }
  }
  // 改变登录方式
  .change-mode{
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    cursor: pointer;
    .short-letter{
      font-size: 24px;
      color: #fff;
    }
    .remember-pwd{
      display: flex;
      font-size: 24px;
      color: #fff;
      .van-checkbox__icon{
        height: 30px;
        line-height: 30px;
      }
      .van-icon{
        width: 30px;
        height: 30px;
        border: 1px solid #fff;
        overflow: hidden;
      }
      .van-checkbox__label{
        font-size: 24px;
        color: #ffffff;
      }
    }
  }
  // 登录
  .submit{
    .van-button{
      width:622px;
      height:81px;
      line-height: 81px;
      background:rgba(105,129,251,1);
      border-radius:10px;
      font-size: 0.28rem;
      cursor: pointer;
      border: none;
      margin: 0 auto;
      cursor: pointer;
      margin-top: 59px;
    }
    .active{
      opacity: 1;
    }
  }
  // 忘记密码、注册
  .forget{
    display: flex;
    justify-content: space-between;
    margin: 33px 0;
    span{
      font-size: 24px;
      color: #fff;
      cursor: pointer;
    }
  }
}
</style>
